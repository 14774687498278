@import 'libs/assets/src/lib/scss/main_style.scss';
@import 'shared/src/_variables.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html:has(#navbarSupportedContent.show) {
  /* remove the main scrollbar when dialog is open */
  overflow: hidden;
  height: 100%;
}

// FOCUS States
// Elements (explicit)
.main-content {
  a:focus::before,
  button:focus::before {
    @extend .focus-overwrite-border !optional;
  }
}
.card-basic,
.badge:is(button):focus-visible,
.badge:is(button):focus,
.badge:is(button).focus {
  &::before {
    @extend .focus-overwrite !optional;
  }
}

.object-card > .card-basic {
  margin: 20px !important;
}

.centered-top {
  margin: 30px 0;
  @media (max-width: $mobile-breakpoint) {
    margin: 0 0;
  }
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $zindex-fixed;
}

// Badge Hacking to fit our design
.badge-pill.mb-4 {
  margin-bottom: 0 !important;
}

// width utilities
.w-md-auto {
  width: auto;
  text-align: center;
}

.dropdown-item {
  &:hover,
  &:focus {
    background: $list-hover;
  }
}
